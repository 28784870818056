import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
import CustomerAwaitingList from './customers/CustomerAwaitingList';
import CustomerAwaitingPhoto from './customers/CustomerAwaitingPhoto';
import ApprovedCustomer from './customers/ApprovedCustomerList';

const AddWallet = Loadable(lazy(() => import("./wallet/AddWallet")));
const WalletList = Loadable(lazy(()=>import("./wallet/WalletList")));
const CardForm = Loadable(lazy(() => import("./cards/CardForm")));
const CustomerList = Loadable(lazy(() => import("./customers/CustomerList")));
const CustomerForm = Loadable(lazy(() => import("./customers/customer-form/CustomerForm")));
const CustomerViewer = Loadable(lazy(() => import("./customers/customer-viewer/CustomerViewer")));
const ClientForm = Loadable(lazy(()=>import("./clients/client-form/ClientForm")))
const ClientList = Loadable(lazy(()=>import("./clients/ClientList")))
const ClientDetails = Loadable(lazy(()=>import('./clients/client-viewer/ClientViewer')))   
const ClientMemberList = Loadable(lazy(()=>import('./clients/clientMemberList'))) 
const CardList = Loadable(lazy(()=>import("./cards/CardList")))  
const WalletManagement = Loadable(lazy(()=>import("./wallet/WalletManagement")))
const AddMembershipType = Loadable(lazy(()=>import("../pages/MembershipTypes/AddMemberShipType")))
const MembershipTypeList = Loadable(lazy(()=>import("../pages/MembershipTypes/MembershipTypeLists")))
const AddSeason = Loadable(lazy(()=>import("./Season/AddSeason")))
const SeasonList = Loadable(lazy(()=>import("./Season/SeasonLists")))
const NonPrimaryMember = Loadable(lazy(()=>import("./customers/customer-form/NonPrimaryCustomerForm")))


const pagesRoutes = [
    {
        path:"/pages/new-client/:id",
        element:<ClientForm/>
    },
    {
        path:"/pages/view-client/:id",
        element:<ClientDetails/>
    },
    {
        path:"/pages/client-member-list/:id",
        element:<ClientMemberList />
    },
    {
        path:"/pages/client-list",
        element:<ClientList/>
    },
    {
        path: '/pages/card/:id',
        element: <CardForm />,
    },
    {
        path: '/pages/all-cards',
        element: <CardList />,
    },
    {
        path: '/pages/wallet-pass/:id',
        element: <AddWallet />,
    },
    {
        path: '/pages/all-wallet-pass',
        element: <WalletList />,
    },
    {
        path: '/pages/customer-list',
        element: <CustomerList condition='all' />,
    },
    {
        path: '/pages/await-approval',
        element: <CustomerAwaitingList condition='pending' />
    },
    {
        path: '/pages/approved-customer',
        element: <ApprovedCustomer condition='current' />
    },
    {
        path: '/pages/await-photo',
        element: <CustomerAwaitingPhoto condition='waiting' />
    },
    {
        path: '/pages/new-customer/:id',
        element: <CustomerForm />,
    },
    {
        path: '/pages/new-no-primary-customer/:id',
        element: <NonPrimaryMember />,
    },
    {
        path: '/pages/view-customer/:id',
        element: <CustomerViewer />,
    },
    {
        path: '/pages/wallet/wallet-management',
        element: <WalletManagement />
    },
    {
        path:"/pages/membership-type/:id",
        element:<AddMembershipType />
    },
    {
        path:"/pages/membership-type-list",
        element:<MembershipTypeList />
    },
    {
        path:"pages/race-season/:id",
        element:<AddSeason />
    },
    {
        path:"pages/all-seasons",
        element:<SeasonList />
    }
]

export default pagesRoutes
