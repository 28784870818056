export const firebaseConfig = {
    apiKey: "AIzaSyDzwIUhsdAnjCRgi7L5E5rqTucE58y_Y74",
    authDomain: "team-gti-management.firebaseapp.com",
    projectId: "team-gti-management",
    storageBucket: "team-gti-management.appspot.com",
    messagingSenderId: "607329795954",
    appId: "1:607329795954:web:8a7b736a978297bac467eb",
    measurementId: "G-TD9R6DDJGQ"
};

export const auth0Config = {
    client_id: 'srw99OVKwHclxb1vD7TnRda9K8HI0qvV',
    domain: 'dev-657daapf.us.auth0.com',
}

export const CLOUDINARY_API = "https://api.cloudinary.com/v1_1/cdmmedia/image/upload"
export const S3_BUCKET = "cred-man01";
export const REGION = "ap-southeast-2";
export const ACCESS_KEY=process.env.ACCESS_KEY;
export const SECRET_ACCESS_KEY=process.env.SECRET_ACCESS_KEY;
export const VERSION = "1.1.7"

// export const API_URI ="http://localhost:8001"
// export const CLIENT_URL="http://localhost:3000"
export const API_URI ="https://api.credentialmanagement.com.au"
export const CLIENT_URL="https://cm01.credentialmanagement.com.au"