import Axios from 'axios'
import { Link } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import { Breadcrumb } from 'app/components'
import React, { useState, useEffect, useContext } from 'react'
import { Avatar, Chip, Grow, Icon, IconButton, TextField, Tooltip } from '@mui/material'
import { Box, styled} from '@mui/system'
import { API_URI } from 'config'
import AuthContext from 'app/contexts/JWTAuthContext'

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}));

const CustomerAwaitingPhoto = ({condition}) => {
    const [isAlive, setIsAlive] = useState(true);
    const [userList, setUserList] = useState([]);
    const token = window.localStorage.getItem('accessToken');
    const [searchValue, setSearchValue] = useState('')
    const { user } = useContext(AuthContext);

    const fetchData = async ()=>{
        const {data} = await Axios.get(`${API_URI}/api/users`, { params:{ condition, search:searchValue   }, headers:{   authorization:`Bearer ${token}` } })
        if (isAlive) setUserList(data.user)
    }

    useEffect(() => {
        fetchData();
        return () => setIsAlive(false)
    }, [isAlive, token, searchValue])

    const columns = [
        {
            name: '', 
            label: 'Photo', 
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let user = userList[dataIndex]

                    return (
                        <FlexBox>
                            <Avatar
                                variant="square"
                                sx={{ width: 48, height: 48 }}
                                src={user?.profile?.Location}
                            />
                        </FlexBox>
                    )
                },
            },
        },
        {
            name: 'firstName',
            label: 'First Name',
            options: {
                filter: true,
            },
        },
        {
            name: 'lastName',
            label: 'Last Name',
            options: {
                filter: true,
            },
        },
        {
            name: 'm_role',
            label: 'Role',
            options: {
                filter: true,
            },
        },
        
        {
            name: 'cardNumber',
            label: 'Card Number',
            options: {
                filter: true,
            },
        },
        {
            name: 'status',
            label: 'Account Status',
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let user = userList[dataIndex]
                    return (
                        <Chip 
                            label={user.status.toUpperCase()} 
                            color="primary" 
                            variant="outlined"
                        />
                    )
                }
            },
        },
        {
            name: 'action',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    const data = userList[dataIndex]
                    return (
                    
                    <FlexBox>
                        <Box flexGrow={1}></Box>
                        {
                            user?.previlage !== "read.only"?(
                                <Tooltip title="Edit Profile">
                                <Link to={data?.membershiptypeLongName === "Non Primary Member"?`/pages/new-no-primary-customer/${data?._id}`:`/pages/new-customer/${data?._id}`}>
                                    <IconButton>
                                        <Icon>edit</Icon>
                                    </IconButton>
                                </Link>
                                </Tooltip>
                            ):null
                        }

                        <Tooltip title="View Profile">
                        <Link to={`/pages/view-customer/${data?._id}`}>
                            <IconButton>
                                <Icon>arrow_right_alt</Icon>
                            </IconButton>
                        </Link>
                        </Tooltip>
                    </FlexBox>
                )},
            },
        },
    ]

    return (
        <Container>
            <div className="breadcrumb">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Pages', path: '/' },
                        { name: 'Awaiting Photo Member List' },
                    ]}
                />
            </div>
            <Box overflow="auto">
                <Box minWidth={750}>
                    <MUIDataTable
                        title={'All Members Awaiting Photo'}
                        data={userList}
                        columns={columns}
                        options={{
                            filterType: 'checkbox',
                            draggableColumns:{
                                enabled:true,
                                transitionTime:500
                            },
                            responsive: 'standard',
                            jumpToPage:true,
                            download:user?.previlage==="read.only"?false:true,
                            selectableRows: "none", 
                            filter: false,
                            print: false,
                            pagination: true,
                            viewColumns: true,
                            elevation: 1,
                            rowsPerPageOptions: [10, 20, 40, 80, 100],
                            customSearchRender: (
                                searchText,
                                handleSearch,
                                hideSearch,
                                options
                            ) => {
                                return (
                                    <Grow appear in={true} timeout={300}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onChange={({ target: { value } }) =>
                                                setSearchValue(value)
                                            }
                                            InputProps={{
                                                style: {
                                                    paddingRight: 0,
                                                },
                                                startAdornment: (
                                                    <Icon
                                                        fontSize="small"
                                                        sx={{ mr: 1 }}
                                                    >
                                                        search
                                                    </Icon>
                                                ),
                                                endAdornment: (
                                                    <IconButton
                                                        onClick={hideSearch}
                                                    >
                                                        <Icon fontSize="small">
                                                            clear
                                                        </Icon>
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    </Grow>
                                )
                            },
                        }}
                    />
                </Box>
            </Box>
        </Container>
    )
}

export default CustomerAwaitingPhoto
